.layout-modal-form {
  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"]';

    #{$inputs}, textarea, select {
      background-color: var(--color-add-04);

      &::placeholder {
        color: var(--color-grey-03);
      }
    }
  }

  &__inner {
    display: flex;
  }

  &__subtitle {
    color: var(--color-grey-03);
    margin-top: 5px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
  }

  &__form-main {
    position: relative;
    display: flex;
  }

  &__policy {
    &.policy-checkbox {
      label {
        &::before {
          top: 0;
        }
      }
    }
    &_top{
       margin-top: 20px;
       margin-bottom: 10px;
    }

  }

  &__success {
    .request-form {
      &__success-description {
        margin-top: 6px;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__field {
    input[type='text'] {
      font-style: normal;
      font-weight: 500;
    }
  }
}

@include respond-up('large') {
  .layout-modal-form {
    &__success {
      .request-form {
        &__success-text-wrap {
          margin-left: 40px;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .layout-modal-form {
    &__inner {
      align-items: center;
      padding: 40px 0 50px;
    }

    &__headings {
      flex: 0 0 var(--grid-column5);
    }

    &__form-wrap {
      flex: 0 0 var(--grid-column5);
      margin-left: var(--grid-gap);
    }

    &__success {
      &.request-form__success {
        padding: 40px 0;
        flex-direction: row;
      }

      .request-form {
        &__success-description {
          width: var(--grid-column3);
        }
      }
    }

    &__subtitle {
      font-size: 16px;
      width: var(--grid-column4);
    }

    &__field {
      input[type='text'] {
        font-size: 16px;
        line-height: 135%;
      }
    }
  }
}

@include respond('s-large') {
  .layout-modal-form {
    &__success {
      .request-form {
        &__success-text-wrap {
          margin-left: 30px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .layout-modal-form {
    &__field {
      + div {
        position: absolute;
        right: 0;
      }
    }

    &__field {
      flex: 0 1 100%;
    }

    &__title {
      font-size: 22px;
    }
  }
}

@include respond('medium') {
  .layout-modal-form {
    &__form-wrap {
      width: calc(var(--grid-column5) - var(--grid-spacer));
    }
  }
}

@include respond-down('medium') {
  .layout-modal-form {
    &__inner {
      flex-direction: column;
      padding: 30px 0 40px;
    }

    &__form-wrap {
      margin-top: 20px;
    }

    &__success {
      &.request-form__success {
        padding: 30px 0;
      }

      .request-form {
        &__success-text-wrap {
          margin-top: 20px;
        }
      }
    }

    &__subtitle {
      font-size: 15px;
    }
  }

  &__field {
    input[type='text'] {
      font-size: 14px;
      line-height: 130%;
    }
  }
}

@include respond-down('small') {
  .layout-modal-form {
    &__form-main {
      flex-direction: column;
    }

    &__field {
      + div {
        margin-top: 18px;

        button {
          width: 100%;
        }
      }
    }

    &__title {
      font-size: 18px;
    }
  }
}